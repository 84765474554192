import React from "react";
import PropTypes from "prop-types";

import LabelledIcon from "theme/components/molecules/LabelledIcon";
import { H1 } from "theme/components/atoms/Typography/Heading";
import EnhanceHero from "./EnhanceHero";
import HeroConfigurationQuery from "./HeroConfigurationQuery.gql";
import ResizedImage from "theme/components/atoms/ResizedImage";
import Link from "theme/components/atoms/Typography/Link";
import generateLinkWidget from "theme/components/helpers/generateLinkWidget";
import { ParallaxBanner } from "react-scroll-parallax";
import IsDesktop from "theme/utils/isDesktop";

const Hero = ({
  loading,
  homePageMainText,
  homePageMainCtaLabel,
  homePageMainCtaLink,
  homePageMainMedia,
}) => {
  if (loading) {
    return <section className="hero hero--skeleton" />;
  }

  return (
    <section className="hero">
      <Link
        className="hero__link"
        buttonAppearance="ghost"
        to={generateLinkWidget(
          homePageMainCtaLink ? homePageMainCtaLink.url : ""
        )}
      >
        <IsDesktop>
          {(isDesktop) => (
            <>
              {isDesktop ? (
                <ParallaxBanner
                  className="hero__image"
                  layers={[
                    {
                      image:
                        homePageMainMedia?.url +
                        "?format=hero&width=1500&height=624&size=1&cover=true",
                      amount: 0.5,
                      props: {
                        style: {
                          backgroundSize: "auto",
                          backgroundRepeat: "no-repeat",
                        },
                      },
                    },
                  ]}
                  style={{
                    height: "625px",
                  }}
                >
                  <div className="hero__image__loader"></div>
                </ParallaxBanner>
              ) : (
                <ResizedImage
                  className="hero__image"
                  src={homePageMainMedia?.url}
                  srcset={homePageMainMedia?.srcset}
                  srcsetFormat={{ medium: "heroTablet", small: "heroMobile" }}
                  alt={homePageMainMedia?.alt}
                  format="hero"
                  cover
                />
              )}
              <div className="hero__content">
                <H1>{homePageMainText}</H1>
                <span className="link button button--thirdly button--default">
                  <LabelledIcon icon="chevron-right" size="tiny" inverted>
                    {homePageMainCtaLabel}
                  </LabelledIcon>
                </span>
              </div>
            </>
          )}
        </IsDesktop>
      </Link>
    </section>
  );
};

Hero.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  cta: PropTypes.string,
  link: PropTypes.string,
};

export default EnhanceHero({ HeroConfigurationQuery })(Hero);
