import compose from "recompose/compose";
import { graphql } from "react-apollo";

export default ({ HeroConfigurationQuery }) =>
  compose(
    graphql(HeroConfigurationQuery, {
      props: ({ data }) => {
        return {
          loading: data.loading,
          homePageMainCtaLabel:
            data.storeConfiguration &&
            data.storeConfiguration.homePageMainCtaLabel,
          homePageMainCtaLink:
            data.storeConfiguration &&
            data.storeConfiguration.homePageMainCtaLink,
          homePageMainMedia:
            data.storeConfiguration &&
            data.storeConfiguration.homePageMainMedia,
          homePageMainCtaColor:
            data.storeConfiguration &&
            data.storeConfiguration.homePageMainCtaColor,
          homePageMainText:
            data.storeConfiguration && data.storeConfiguration.homePageMainText,
        };
      },
    })
  );
