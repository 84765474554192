import React from "react";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import { withRouter } from "react-router";
import { graphql } from "react-apollo";
import withGenderFilter from "theme/components/helpers/withGenderFilter";
import withTrackPage from "theme/modules/Analytics/withTrackPage";
import { SuccessAlert } from "theme/components/molecules/Alert";
import CmsPageQuery from "theme/pages/CmsPage/CmsPageQuery.gql";
import WysiwygV2 from "theme/modules/WysiwygV2";
import Hero from "theme/components/organisms/Hero";
import HomeSeo from "theme/modules/Home/HomeSeo";
import { trackGlobalPage } from "../../components/helpers/AnalyticsV4/TrackAnlyticsV4";

const Home = (props) => {
  return (
    <>
      <HomeSeo page={props.cms} />
      <div>
        {props.location.state && props.location.state.successMessage && (
          <SuccessAlert>{props.location.state.successMessage}</SuccessAlert>
        )}
        <Hero />
        {!props.loading && <WysiwygV2 content={props.cms.contentWysiwyg} />}
      </div>
    </>
  );
};

export default compose(
  graphql(CmsPageQuery, {
    options: () => ({
      variables: {
        identifiers: ["home"],
      },
    }),
    props: ({ data }) => ({
      loading: data.loading,
      cms:
        !data.loading &&
        data.cmsPageList &&
        data.cmsPageList.length > 0 &&
        data.cmsPageList[0],
    }),
  }),
  withRouter,
  withTrackPage("Home"),
  trackGlobalPage("home"),
  withGenderFilter(),
  withProps((props) => {
    if (props.currentGender) {
      props.resetGenderFilter();
    }
  })
)(Home);
