import React from "react";
import { Helmet } from "react-helmet-async";

const HomeSeo = ({ cms }) => {
  return (
    <Helmet>
      {cms && (
        <>
          <title>{cms.meta_title ?? cms.title}</title>
          {cms.description && (
            <meta name="description" content={cms.description} />
          )}
          <meta name="og:title" content={cms.meta_title ?? cms.title} />
          <meta
            name="og:description"
            content={cms.meta_description ?? cms.title}
          />
        </>
      )}
    </Helmet>
  );
};

export default HomeSeo;
